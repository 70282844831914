

















































































































import Vue, { PropType } from "vue";
import { IApp } from "@/interfaces";
import {
  CandidateCardViewOptions,
  ViewCandidatesOptions
} from "@/interfaces/recruiter/admin/all_candidates";
import ProfileDetails from "@/components/recruiter/admin/ProfileDetails.vue";
import ApprovedDeclinedButton from "@/components/recruiter/admin/ApprovedDeclinedButton.vue";
import {
  ActionCandidate,
  ActionCandidatePayload
} from "@/store/modules/recruiter_admin/interfaces";
import { mapActions } from "vuex";
import { ACTION_CANDIDATE } from "@/store/modules/recruiter_admin/constants";
import { User } from "@/interfaces/data_objects/user";
import { get_name_initials } from "@/utils/global";
import { SearchedCandidate } from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "CandidateCard",
  data() {
    return {
      loading: false,
      user: null as null | User
    };
  },
  computed: {
    IApp() {
      return IApp;
    },
    CandidateCardViewOptions() {
      return CandidateCardViewOptions;
    },
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    }
  },
  components: { ApprovedDeclinedButton, ProfileDetails },
  props: {
    candidate: {
      type: Object as PropType<SearchedCandidate>,
      required: true
    },
    view_type: {
      type: String as PropType<ViewCandidatesOptions>,
      required: true
    }
  },
  methods: {
    get_name_initials,
    ...mapActions("recruiter_admin", {
      action_candidate: ACTION_CANDIDATE
    }),
    async view_profile() {
      if (
        this.candidate.status === IApp.ResumeUploadsStatus.IN_PROGRESS ||
        !this.candidate.upload_response ||
        !this.get_name()
      )
        return;
      sessionStorage.setItem("job", "");
      sessionStorage.setItem("user", this.candidate.candidate_id.toString());
      sessionStorage.setItem("upload", this.candidate.upload_response_id);
      await this.$router.push("/recruiter/candidates/profile");
    },
    // Function to return experience
    get_experience(): string {
      if (!this.candidate.upload_response) return "";
      const profile = this.candidate.upload_response.profile;
      if (!profile) return "";
      if (!profile.total_experience) return "";
      return profile.total_experience;
    },
    get_name(): string {
      const profile = this.candidate.upload_response.profile;
      if (!profile) return "";
      if (Object.values(profile.personal_details).length === 0) return "";
      return Object.values(profile.personal_details.name).join(" ");
    },
    async btn_action(result: boolean) {
      this.loading = true;
      const payload: ActionCandidatePayload = {
        action: result ? ActionCandidate.ACTIVATE : ActionCandidate.DEACTIVATE,
        user_ids: [this.candidate.candidate_id]
      };
      await this.action_candidate(payload);
      this.$emit("action");
      this.loading = false;
    }
  }
});
