





















































































import Vue from "vue";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import AllCandidatesHeader from "@/components/recruiter/admin/AllCandidatesHeader.vue";
import CandidateCard from "@/components/recruiter/admin/CandidateCard.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  GET_ALL_CANDIDATES,
  VIEW_CANDIDATE_DATA,
  VIEW_CANDIDATE_FILTER
} from "@/store/modules/recruiter_admin/constants";
import { GetAllCandidatesPayload } from "@/store/modules/recruiter_admin/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { Candidates } from "@/interfaces/data_objects/candidates";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "AllCandidates",
  components: {
    DataNotFound,
    CandidateCard,
    AllCandidatesHeader
  },
  data() {
    return {
      candidate_filter: ViewCandidatesOptions.PENDING as ViewCandidatesOptions,
      loading: true,
      pagination: 1
    };
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    },
    ...mapGetters("recruiter_admin", {
      get_all_candidates: GET_ALL_CANDIDATES,
      get_candidate_filter: VIEW_CANDIDATE_FILTER
    }),
    get_title(): string {
      return this.$t("recruiter.no-candidates").toString();
    },
    pending(): string {
      return this.$t("recruiter.pending-request").toString();
    }
  },
  mounted() {
    this.candidate_filter = this.get_candidate_filter;
    this.fetch_candidates(this.get_all_candidates.pagination);
  },
  methods: {
    ...mapActions("recruiter_admin", {
      fetch_all_candidates: GET_ALL_CANDIDATES
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    ...mapMutations("recruiter_admin", {
      set_all_candidates: GET_ALL_CANDIDATES,
      set_view_candidates_date: VIEW_CANDIDATE_DATA,
      set_candidate_filter: VIEW_CANDIDATE_FILTER
    }),
    update_candidate_filter(value: ViewCandidatesOptions) {
      this.candidate_filter = value;
      this.set_candidate_filter(this.candidate_filter);
      this.get_all_candidates.pagination = 1;
      this.fetch_candidates();
    },
    async view_candidate(data: Candidates.Candidates) {
      this.set_view_candidates_date(data);
      this.set_candidate_filter(this.candidate_filter);
      await this.$router.push("view");
    },
    async fetch_candidates(page = 1, limit = 12) {
      this.loading = true;
      const approved = this.candidate_filter !== ViewCandidatesOptions.PENDING;
      const payload: GetAllCandidatesPayload = {
        page: page - 1,
        limit_per_page: limit,
        removed: this.candidate_filter === ViewCandidatesOptions.DECLINED,
        approved
      };

      const response = await this.fetch_all_candidates(payload);
      if (!response) {
        this.set_root_error("Failed to get matching candidates");
        await this.$router.push("/recruiter/dashboard");
        return;
      }
      this.set_all_candidates(response);
      this.loading = false;
    }
  }
});
