var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"d-flex flex-column align-center justify-space-between",class:{
    'no-cursor':
      _vm.candidate.status === _vm.IApp.ResumeUploadsStatus.IN_PROGRESS ||
      !_vm.candidate.upload_response ||
      !_vm.get_name()
  },attrs:{"id":"candidate_card"},on:{"click":_vm.view_profile}},[_c('div',{staticClass:"d-flex align-center justify-space-between card-head"},[_c('v-chip',{staticClass:"experience-chip pr-5 py-4 mr-6",attrs:{"color":"light-bg"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-circle-medium ")]),_c('p',{staticClass:"mb-0 base-body-text"},[(
            _vm.candidate.status !== _vm.IApp.ResumeUploadsStatus.IN_PROGRESS &&
            _vm.get_experience()
          )?[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.get_experience()))+" "+_vm._s(_vm.$t("recruiter.view-job.experience-min-title"))+" ")]:(
            _vm.candidate.status === _vm.IApp.ResumeUploadsStatus.IN_PROGRESS
          )?[_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","color":"primary"}})]:_vm._e()],2)],1),(_vm.candidate.status === _vm.IApp.ResumeUploadsStatus.IN_PROGRESS)?_c('v-skeleton-loader',{attrs:{"type":"avatar"}}):(
        _vm.candidate.status === _vm.IApp.ResumeUploadsStatus.COMPLETED && !_vm.get_name()
      )?_c('v-tooltip',{attrs:{"color":"white","max-width":"330px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"light-bg",attrs:{"icon":"","color":"danger"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alert-outline")])],1)]}}])},[_c('v-card',[_c('v-card-title',[_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.admin.approvals.parser-failed-title"))+" ")])]),_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("recruiter.admin.approvals.parser-failed-description"))+" ")])],1)],1):_vm._e()],1),_c('ProfileDetails',{attrs:{"candidate":_vm.candidate}}),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"1rem"}},[(_vm.view_type === _vm.ViewCandidatesOptions.PENDING)?[_c('ApprovedDeclinedButton',{attrs:{"loading":_vm.loading,"disabled":_vm.candidate.status === _vm.IApp.ResumeUploadsStatus.IN_PROGRESS,"text":_vm.$t('recruiter.admin.approvals.decline'),"view_type":_vm.CandidateCardViewOptions.declined},on:{"btn_action":_vm.btn_action}}),_c('ApprovedDeclinedButton',{attrs:{"loading":_vm.loading,"disabled":_vm.candidate.status === _vm.IApp.ResumeUploadsStatus.IN_PROGRESS ||
          _vm.candidate.status === _vm.IApp.ResumeUploadsStatus.FAILED,"text":_vm.$t('recruiter.admin.approvals.approve'),"view_type":_vm.CandidateCardViewOptions.approved},on:{"btn_action":_vm.btn_action}})]:(_vm.view_type === _vm.ViewCandidatesOptions.APPROVED)?[_c('p',{staticClass:"mb-0 base-body-text success--text"},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("recruiter.admin.approvals.approved"))+" ")],1)]:(_vm.view_type === _vm.ViewCandidatesOptions.DECLINED)?[_c('p',{staticClass:"mb-0 base-body-text danger--text"},[_c('v-icon',{attrs:{"color":"danger"}},[_vm._v(" mdi-close-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("recruiter.admin.approvals.declined"))+" ")],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }