



















































import Vue, { PropType } from "vue";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";

export default Vue.extend({
  name: "AllCandidatesHeader",
  computed: {
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    }
  },
  props: {
    filter: {
      type: String as PropType<ViewCandidatesOptions>,
      required: true
    }
  }
});
