








































import Vue, { PropType } from "vue";
import { get_name_initials } from "@/utils/global";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import {
  SearchedCandidate,
  SearchedCandidateProfile
} from "@/store/modules/recruiter/interfaces";
import { IApp } from "@/interfaces";

export default Vue.extend({
  name: "ProfileDetails",
  components: { DataNotFound },
  data() {
    return {
      name: "" as string,
      name_initials: "" as string,
      avatar_uri: "" as string,
      designation: "" as string
    };
  },
  computed: {
    IApp() {
      return IApp;
    }
  },
  props: {
    candidate: {
      type: Object as PropType<SearchedCandidate>,
      required: true
    }
  },
  mounted() {
    if (!this.candidate.upload_response) return;
    const profile = this.candidate.upload_response.profile;
    if (profile) {
      // Fetching name
      this.name = this.get_name(profile);
      // Fetching Designation
      this.designation = this.get_designation(profile);
      this.avatar_uri = this.candidate.avatar_uri;
      if (!this.avatar_uri) this.name_initials = get_name_initials(this.name);
    }
  },
  methods: {
    get_name(applicant: SearchedCandidateProfile): string {
      if (Object.values(applicant.personal_details).length <= 0) return "";
      return Object.values(applicant.personal_details.name).join(" ");
    },
    get_designation(applicant: SearchedCandidateProfile): string {
      if (
        applicant.work_history_detailed &&
        Object.values(applicant.work_history_detailed).length > 0
      )
        return Object.values(applicant.work_history_detailed)[0].job_title;
      return "";
    },
    getTitle(): string {
      return this.$t(
        "recruiter.admin.approvals.parser-failed-title"
      ).toString();
    }
  }
});
